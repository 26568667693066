import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TextInput } from "Common/Components/form/input";
import { FormEvent } from "react";
import { toast } from "react-toastify";
import { createCategory, updateCategory } from "services/articles";

export const AddEditCategoryForm = ({
  category,
  close,
  category_id,
}: {
  category?: ArticleCategory;
  close: () => void;
  category_id?: number;
}) => {
  const queryClient = useQueryClient();
  const createCategoryAction = useMutation({
    mutationFn: createCategory,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["articleCategories"] });
      toast.success(res, { autoClose: 2000 });
      close();
    },
  });
  const updateCategoryAction = useMutation({
    mutationFn: updateCategory,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["articleCategories"] });
      toast.success(res, { autoClose: 2000 });
      close();
    },
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const en_title = (formData.get("en_title") as string) || "";
    const fr_title = (formData.get("fr_title") as string) || "";
    const es_title = (formData.get("es_title") as string) || "";
    const colour_rgb = (formData.get("colour_rgb") as string) || "";

    const categoryData: CategoryData = {
      content: [
        {
          language_code: "en",
          title: en_title,
          colour_rgb,
        },
        {
          language_code: "fr",
          title: fr_title,
          colour_rgb,
        },
        {
          language_code: "es",
          title: es_title,
          colour_rgb,
        },
      ],
    };
    if (!category) {
      await createCategoryAction.mutateAsync(categoryData);
    } else {
      await updateCategoryAction.mutateAsync({
        ...categoryData,
        category_id: category_id as number,
      });
    }
  };

  const frTitle = category?.translations.find(
    (category) => category.language_code === "fr",
  )?.title;
  const esTitle = category?.translations.find(
    (category) => category.language_code === "es",
  )?.title;
  return (
    <form
      onSubmit={handleSubmit}
      className="w-full flex flex-col gap-4 dark:bg-zink-700 dark:text-zink-100"
    >
      <div className="w-full grid grid-cols-2 gap-2">
        <TextInput
          name="en_title"
          label="Title (English)"
          placeholder="Enter title in English"
          defaultValue={category?.translation.title}
        />
        <TextInput
          name="fr_title"
          label="Title (French)"
          placeholder="Enter title in French"
          defaultValue={frTitle}
        />
      </div>
      <div className="w-full grid grid-cols-2 gap-2">
        <TextInput
          name="es_title"
          label="Title (Espansol)"
          placeholder="Enter title in Espansol"
          defaultValue={esTitle}
        />
        <TextInput
          name="colour_rgb"
          label="RGB Color"
          placeholder="red,green,blue"
          defaultValue={category?.translation.colour_rgb}
        />
      </div>

      <div className="w-full flex justify-end items-start gap-2">
        <button
          onClick={close}
          className="p-2 px-4 rounded text-white font-semibold text-base bg-red-500 hover:bg-red-800"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="p-2 px-4 rounded text-white font-semibold text-base bg-blue-500 hover:bg-blue-800"
        >
          {category ? "Save" : "Add"}
        </button>
      </div>
    </form>
  );
};
