import { useQuery } from "@tanstack/react-query";
import { Modal } from "Common/Components/modal-2";
import { Plus } from "lucide-react";
import { Fragment, useState } from "react";
import { AddEditCategoryForm } from "./add-edit-category-form";
import {
  EllipsisVerticalIcon,
  PencilSquareIcon,
  PhotoIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { getCategories } from "services/articles";
import { DeleteCategory } from "./delete-category";
import { UpdateCategoryImage } from "./update-category-image";

export default function CategoriesList() {
  const [createCategory, setCreateCategory] = useState(false);
  const { data: categories, isLoading } = useQuery({
    queryKey: ["articleCategories"],
    queryFn: getCategories,
    staleTime: Infinity,
  });

  return (
    <Fragment>
      <Modal open={createCategory} close={() => setCreateCategory(false)}>
        <Modal.Header>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddEditCategoryForm close={() => setCreateCategory(false)} />
        </Modal.Body>
      </Modal>
      <div className="w-full bg-white dark:bg-zink-700 dark:text-zink-100 flex flex-col items-start">
        <div className="w-full flex items-center justify-between px-6 py-4">
          <h5 className="text-15">Category List</h5>

          <button
            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 flex items-center gap-2"
            onClick={() => setCreateCategory(true)}
          >
            <Plus className="inline-block size-4" />
            Add Category
          </button>
        </div>

        <div className="w-full grid grid-cols-1 lg:grid-cols-4 items-start gap-5 p-6">
          {isLoading ? (
            <p>fetching categories...</p>
          ) : categories ? (
            categories.map((category) => (
              <SingleCategory key={category.id} category={category} />
            ))
          ) : null}
        </div>
      </div>
    </Fragment>
  );
}

const SingleCategory = ({ category }: { category: ArticleCategory }) => {
  const [options, setOptions] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [updateImages, setUpdateImages] = useState(false);

  return (
    <Fragment>
      <Modal open={editCategory} close={() => setEditCategory(false)}>
        <Modal.Header>
          <Modal.Title>
            Edit "{category?.translation?.title}" category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddEditCategoryForm
            category={category}
            close={() => setEditCategory(false)}
            category_id={category.id}
          />
        </Modal.Body>
      </Modal>
      <DeleteCategory
        open={deleteCategory}
        close={() => setDeleteCategory(false)}
        id={category.id}
        categoryTitle={category.translation.title}
      />
      <UpdateCategoryImage
        open={updateImages}
        close={() => setUpdateImages(false)}
        category_id={category.id}
        categoryTitle={category.translation.title}
      />
      <div
        key={category.id}
        className="w-full h-40 rounded-md p-4 relative"
        style={{
          backgroundColor: `rgb(${category.translation.colour_rgb})`,
        }}
      >
        <EllipsisVerticalIcon
          className="size-6 text-gray-200 absolute top-2 right-2 cursor-pointer z-50"
          onClick={() => setOptions((prev) => !prev)}
        />
        <p className="uppercase text-xl font-bold text-white">
          {category.translation.title}
        </p>
        {options ? (
          <div className="absolute top-2 right-8 lg:-right-28 z-50 w-max flex flex-col gap-1.5 bg-white p-2 shadow-sm rounded-md">
            <button
              className="w-full flex items-center gap-1 text-base text-gray-600 hover:text-gray-800"
              onClick={() => {
                setOptions(false);
                setEditCategory(true);
              }}
            >
              <PencilSquareIcon className="size-4 text-amber-700" />
              Edit
            </button>
            <button
              onClick={() => {
                setOptions(false);
                setDeleteCategory(true);
              }}
              className="w-full flex items-center gap-1 text-base text-gray-600 hover:text-gray-800"
            >
              <TrashIcon className="size-4 text-red-700 flex-none" /> Delete
            </button>
            <button
              onClick={() => {
                setOptions(false);
                setUpdateImages(true);
                // setDeleteCategory(true);
              }}
              className="w-full flex items-center gap-1 text-base text-gray-600 hover:text-gray-800"
            >
              <PhotoIcon className="size-4 text-blue-700 flex-none" /> Update
              Image
            </button>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};
