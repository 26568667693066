import { useQuery } from "@tanstack/react-query";
import { Plus } from "lucide-react";
import { Fragment, useState } from "react";
import { getTemplates } from "services/email-template";
import { AddEditTemplate } from "./add-edit-template";

export const EmailManager = () => {
  const [createTemplate, setCreateTemplate] = useState(false);
  const [templateId, setTemplateId] = useState<number | null>(null);

  const { data: templates, isLoading } = useQuery({
    queryFn: getTemplates,
    queryKey: ["email-templates"],
    staleTime: Infinity,
  });
  return (
    <Fragment>
      <AddEditTemplate
        open={createTemplate}
        close={() => {
          setCreateTemplate(false);
          setTemplateId(null);
        }}
        id={templateId as number}
      />
      <div className="w-full bg-white dark:bg-zink-700 dark:text-zink-100 flex flex-col items-start">
        <div className="w-full flex items-center justify-between px-6 py-4">
          <h5 className="text-2xl">Email Templates</h5>
          <button
            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 flex items-center gap-2"
            onClick={() => setCreateTemplate(true)}
          >
            <Plus className="inline-block size-4" />
            Create Email Template
          </button>
        </div>

        <ul className="w-full flex flex-col items-start divide-y divide-gray-200 px-6">
          {isLoading ? (
            <p>Fetching your templates...</p>
          ) : templates ? (
            templates.map((template) => (
              <li
                className="w-full flex items-center justify-between py-4 list-disc"
                key={template.id}
              >
                <p className="font-medium text-lg capitalize">
                  {template.name}
                </p>
                <button
                  onClick={() => {
                    setTemplateId(template.id);
                    setCreateTemplate(true);
                  }}
                  className="text-sm font-semibold text-blue-500 hover:text-blue-900"
                >
                  Edit
                </button>
              </li>
            ))
          ) : (
            <p>An error occured. Pls try again</p>
          )}
        </ul>
      </div>
    </Fragment>
  );
};
