import { useQuery } from "@tanstack/react-query";
import { Plus, Search } from "lucide-react";
import { Fragment, useState } from "react";
import { getCategories, getTopicsByCategoryId } from "services/articles";
import { AddEditTopic } from "./add-edit-topic";
import { DeleteTopic } from "./delete-topic";

export default function TopicList() {
  const [categoryId, setCategoryId] = useState<ID | null>(null);
  const [createTopic, setCreateTopic] = useState(false);

  const { data: categories } = useQuery({
    queryKey: ["articleCategories"],
    queryFn: getCategories,
    staleTime: Infinity,
  });

  const { data: topics, isLoading } = useQuery({
    queryKey: ["articles.topics", categoryId],
    queryFn: () => getTopicsByCategoryId(categoryId as number),
    enabled: !!categoryId,
  });

  return (
    <Fragment>
      <AddEditTopic open={createTopic} close={() => setCreateTopic(false)} />
      <div className="w-full bg-white dark:bg-zink-700 dark:text-zink-100 flex flex-col items-start">
        <div className="w-full flex items-center justify-between px-6 py-4">
          <h5 className="text-15">Topic List</h5>
          <button
            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 flex items-center gap-2"
            onClick={() => setCreateTopic(true)}
          >
            <Plus className="inline-block size-4" />
            Add Topic
          </button>
        </div>

        <div className="w-full flex items-center gap-6 border-y border-dashed px-6 py-4">
          <div className="relative xl:col-span-2">
            <input
              type="search"
              className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Search for a topic..."
              autoComplete="off"
            // onChange={(e) => filterSearchData(e)}
            />
            <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
          </div>
          <div className="xl:col-span-2">
            <select
              onChange={(e) =>
                setCategoryId(+e.target.value !== 0 ? +e.target.value : null)
              }
              className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
            >
              <option value={0}>Select Category</option>
              {categories?.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.translation.title}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="w-full flex items-center justify-center p-6">
          {!categoryId ? (
            <p>Please select a category</p>
          ) : isLoading ? (
            <p>fetching topics...</p>
          ) : !topics || topics.length === 0 ? (
            <p>No topics found</p>
          ) : (
            <div className="w-full flex flex-col items-start divide-y divide-gray-200">
              {topics.map((topic) => (
                <SingleTopic key={topic.id} topic={topic} />
              ))}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

const SingleTopic = ({ topic }: { topic: ArticleTopic }) => {
  const [editTopic, setEditTopic] = useState(false);
  const [deleteTopic, setDeleteTopic] = useState(false);
  return (
    <Fragment>
      <AddEditTopic
        open={editTopic}
        close={() => setEditTopic(false)}
        topic={topic}
        category_id={topic.translation.category.id}
      />
      <DeleteTopic
        open={deleteTopic}
        close={() => setDeleteTopic(false)}
        id={topic.id}
        topicTitle={topic.translation.title}
        categoryId={topic.translation.category_id}
      />
      <div className="w-full flex items-center py-4 justify-between">
        <p className="flex items-center gap-2 text-base font-bold dark:text-zink-100 text-gray-800">
          <span className="size-1 rounded-full bg-gray-900 dark:bg-zink-200" />
          {topic.translation.title}
        </p>
        <div className="flex items-center gap-3">
          <button
            className="text-sm font-semibold text-blue-700 hover:text-blue-800"
            onClick={() => setEditTopic(true)}
          >
            Edit
          </button>
          <button
            className="text-sm font-semibold text-red-700 hover:text-red-800"
            onClick={() => setDeleteTopic(true)}
          >
            Delete
          </button>
        </div>
      </div>
    </Fragment>
  );
};
