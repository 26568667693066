import { ArrowLongLeftIcon } from "@heroicons/react/24/solid";
import { CreateArticle } from "pages/Components/articles/new/create-article";
import { CreateQuestion } from "pages/Components/articles/new/create-question";
import { useState } from "react";
import { Link } from "react-router-dom";

export const CreateNewArticle = () => {
  const [articleCreationStep, setArticleCreationStep] = useState(1);
  const [articleQuestionData, setArticleQuestionData] =
    useState<ArticleQuestion | null>(null);

  const saveArticleQuestionData = (data: ArticleQuestion) => {
    setArticleQuestionData(data);
  };

  return (
    <div className="w-full bg-white dark:bg-zink-700 dark:text-zink-100 flex flex-col items-start px-6 py-4 gap-6">
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Link
            to={"/view-articles"}
            className="size-10 rounded-full bg-gray-50 dark:bg-transparent dark:border dark:border-zink-200 flex items-center justify-center group hover:bg-gray-100 dark:hover:bg-zink-700 transition-all duration-100 ease-in-out"
          >
            <ArrowLongLeftIcon className="size-6 text-gray-600 dark:text-zink-100 dark:group-hover:text-zink-100 group-hover:text-gray-900" />
          </Link>
          <h3>Create New Article</h3>
        </div>
        <p>Step {articleCreationStep} of 2</p>
      </div>
      {articleCreationStep === 1 ? (
        <CreateQuestion
          updateStep={setArticleCreationStep}
          onSendArticleQuestionData={saveArticleQuestionData}
        />
      ) : articleCreationStep === 2 ? (
        <CreateArticle
          articleQuestionData={articleQuestionData as ArticleQuestion}
        />
      ) : null}
    </div>
  );
};
