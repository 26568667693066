import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ComponentProps, useEffect, useState } from "react";
import { getNotifications, updateNotification } from "services/notifications";
import { cn } from "utils/cn";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const languages: Array<Languages> = [
  "english",
  "arabic",
  "bengali",
  "chinese",
  "french",
  "german",
  "hindi",
  "italian",
  "japanese",
  "persian_farsi",
  "portuguese",
  "romanian",
  "russian",
  "spanish",
  "swahili",
  "swedish",
  "tagalog",
  "tamil",
  "thai",
  "ukrainian",
  "urdu",
  "vietnamese",
  "persianFarsi",
];

export const PushNotification = () => {
  const [isNotificationLive, setIsNotificationLive] = useState(true);
  const [currentNotificationStatus, setCurrentNotificationStatus] =
    useState<number>(0);
  const [languageContent, setLanguageContent] = useState<
    Record<Languages, string>
  >({} as Record<Languages, string>);

  const { data: notifications } = useQuery({
    queryKey: ["push-notification"],
    queryFn: getNotifications,
    staleTime: Infinity,
  });

  console.log(notifications);

  const queryClient = useQueryClient();
  const updateNotificationAction = useMutation({
    mutationFn: updateNotification,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["push-notification"] });
    },
  });

  // Initialize language content from notifications when they load
  useEffect(() => {
    if (notifications) {
      const initialContent: Record<Languages, string> = {} as Record<Languages, string>;
      languages.forEach((lang) => {
        initialContent[lang] = notifications.notification[lang] || "";
      });
      setLanguageContent(initialContent);
      setCurrentNotificationStatus(notifications?.status || 0);
    }
  }, [notifications]);

  // Add lazy loading for the language editors
  const [visibleLanguages, setVisibleLanguages] = useState<Languages[]>(
    languages.slice(0, 5) // Start with first 5 languages
  );

  const loadMoreLanguages = () => {
    setVisibleLanguages(languages.slice(0, visibleLanguages.length + 5));
  };

  const handleSave = async () => {
    if (!languageContent) {
      console.log("Language content cannot be empty.");
      return;
    }

    const data: UpdateNotificationData = {
      notification: isNotificationLive,
      status: currentNotificationStatus,
      ...languageContent,
    };

    languages.forEach((lang) => {
      if (languageContent[lang]) {
        data[lang] = languageContent[lang];
      }
    });

    try {
      await updateNotificationAction.mutateAsync(data);
    } catch (error) {
      console.error("Failed to update notification:", error);
    }
  };

  return (
    <div className="w-full h-full bg-white dark:bg-zink-700 dark:text-zink-100 p-10 flex flex-col items-start gap-6">
      <h5 className="text-15">Push Notification</h5>
      <div className="w-full flex items-center justify-between">
        <Toggle label="Send to all mobile users" isActive={currentNotificationStatus === 3} onClick={() => setCurrentNotificationStatus(3)} />
        <Toggle label="Send to iOS Users" isActive={currentNotificationStatus === 1} onClick={() => setCurrentNotificationStatus(1)} />
        <Toggle label="Send to Android Users" isActive={currentNotificationStatus === 2} onClick={() => setCurrentNotificationStatus(2)} />
      </div>
      <div className="w-full">
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 items-start gap-6">
          {visibleLanguages.map((lang, index) => (
            <div className="w-full flex flex-col items-start gap-2" key={lang}>
              <p className="text-sm text-zinc-700 dark:text-zink-100 font-medium capitalize">
                {lang}
              </p>
              <ReactQuill
                theme="snow"
                value={languageContent[lang] || ""}
                onChange={(value) => {
                  setLanguageContent((prev) => ({ ...prev, [lang]: value }));
                }}
                className="w-full"
                modules={{ toolbar: ["bold", "italic", "underline"] }}
              />
            </div>
          ))}

          {visibleLanguages.length < languages.length && (
            <button
              className="px-4 py-2 rounded bg-gray-200 hover:bg-gray-300"
              onClick={loadMoreLanguages}
            >
              Load More Languages
            </button>
          )}

          <button
            className="px-4 py-2 rounded bg-blue-700 hover:bg-blue-800 text-blue-100 font-semibold"
            disabled={updateNotificationAction.isPending}
            onClick={handleSave}
          >
            {updateNotificationAction.isPending ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

const Toggle = ({
  isActive,
  className,
  label,
  ...props
}: { isActive: boolean; label: string } & ComponentProps<"div">) => (
  <div className="flex flex-col items-start gap-2 p-4 px-8 rounded-2xl border border-zink-400 dark:border-zink-200">
    <p className="text-zinc-800 dark:text-zink-100 font-medium text-base">
      {label}
    </p>
    <div
      className={cn(
        "w-16 h-8 rounded-full relative transition-all duration-500 ease-in shadow-md",
        {
          "bg-gray-900 border-0": isActive,
          "bg-gray-100": !isActive,
        },
        className,
      )}
      {...props}
    >
      <span
        className={cn(
          "size-5 rounded-full absolute top-1/2 -translate-y-1/2 transition-all duration-500 ease-in",
          {
            "left-10 bg-white": isActive,
            "left-2 bg-gray-900": !isActive,
          },
        )}
      // onClick={() => setIsNotificationLive((prev) => !prev)}
      />
    </div>
  </div>
);
