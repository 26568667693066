import { ComponentProps } from "react";

export const TextInput = ({
  name,
  label,
  ...props
}: ComponentProps<"input"> & { label: string }) => {
  return (
    <div className="w-full flex flex-col gap-1.5">
      <label
        htmlFor={name}
        className="text-xs font-semibold text-gray-600 dark:text-zink-100"
      >
        {label}
      </label>
      <input
        id={name}
        type="text"
        name={name}
        className="w-full border border-gray-200 p-2 rounded text-sm placeholder:text-xs focus:outline-none focus:border-gray-800 dark:bg-zink-700 dark:text-zink-100 dark:border-zink-400"
        {...props}
      // placeholder="Enter title in English"
      // defaultValue={category?.translation.title}
      />
    </div>
  );
};
