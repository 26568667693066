import { apis } from "api";
import AxiosApi from "api/api";

export const getNotifications = async () => {
  return AxiosApi.get<NotificationData>(apis.notification.get).then((res) => {
    return res.data.data;
  });
};
export const updateNotification = async (data: UpdateNotificationData) => {
  return AxiosApi.post<{ message: string }>(
    apis.notification.update,
    data,
  ).then((res) => {
    return res.data.message;
  });
};
