import { apis } from "api";
import AxiosApi from "api/api";

export const getTemplates = async () => {
  return AxiosApi.get<Array<EmailTemplate>>(apis.email.list).then(
    (res) => res.data
  );
};

export const getSingleTemplate = async (id: number) =>
  AxiosApi.get<EmailTemplate>(apis.email.getSingleTemplate(id)).then(
    (res) => res.data
  );

export const createTemplate = async (data: EmailTemplateData) =>
  AxiosApi.post<{ message: string }>(apis.email.create, data).then(
    (res) => res.data.message
  );

export const editTemplate = async ({
  id,
  data,
}: {
  id: number;
  data: Omit<EmailTemplateData, "name">;
}) =>
  AxiosApi.put<{ message: string }>(apis.email.update(id), data).then(
    (res) => res.data.message
  );

export const uploadTemplateImage = async (formData: FormData) =>
  AxiosApi.post(apis.email.uploadImage, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
    },
  }).then((res) => res.data.data);

