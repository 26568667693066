import { ComponentProps, PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { XMarkIcon } from "@heroicons/react/24/solid";

export const Modal = ({
  open,
  close,
  className,
  children,
  ...props
}: ComponentProps<"div"> & {
  open: boolean;
  close: () => void;
}) => {
  const content = (
    <div className="w-screen h-screen fixed top-0 left-0 z-[99999]">
      <div
        className="absolute w-full h-full top-0 left-0 bg-black/20 z-30"
        onClick={close}
      />
      <div
        className="absolute w-1/2 h-max max-h-5/6 bg-white dark:bg-zink-700 dark:text-zink-100 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mx-auto z-[90] rounded-md flex flex-col items-start"
        {...props}
      >
        {children}
        <XMarkIcon
          className="size-6 text-gray-700 dark:text-zink-100 hover:text-gray-800 cursor-pointer absolute top-4 right-4"
          onClick={close}
        />
      </div>
    </div>
  );

  return open
    ? createPortal(content, document.getElementById("modal") as HTMLElement)
    : null;
};

const ModalHeader = ({ children }: PropsWithChildren) => (
  <div className="w-full p-4 border-b border-gray-200 dark:border-zink-200">
    {children}
  </div>
);
const ModalTitle = ({ children }: PropsWithChildren) => (
  <h4 className="text-base font-semibold text-gray-800">{children}</h4>
);
const ModalBody = ({ children }: PropsWithChildren) => (
  <div className="w-full p-4 flex flex-col items-start gap-4">{children}</div>
);

Modal.Header = ModalHeader;
Modal.Title = ModalTitle;
Modal.Body = ModalBody;
